import React from 'react'

const Footer = () => {
    return (
        <div className="c-footer">
            Amaretto CMS v1.0 - developed by Amar Vuković
        </div>
    )
}

export default Footer
