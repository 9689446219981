import React from 'react'

interface iPageTitleProps {
    name: string;
    description?: string;
}

const PageTitle: React.FC<iPageTitleProps> = ({name, description}) => {
    return (
        <h2 className="c-page__title">
            {name}
            <div className="c-page__title-desc">{description}</div>
        </h2>
    )
}

export default PageTitle
