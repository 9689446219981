import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Navigation from '../navigation/Navigation';
import Clients from '../clients/Clients';
import ClientsAdd from '../clients/ClientsAdd';
import Client from '../clients/Client';
import TranslationAdd from '../translation/TranslationAdd';
import Translations from '../translation/Translations';
import Translation from '../translation/Translation';
import Footer from "../shared/Footer";


const AppPage = () => {

    return (
        <BrowserRouter basename={'/'}>
            <Navigation />
            <Container maxWidth="lg">
                <Switch>
                    <Route exact path="/" component={Clients} />
                    <Route path="/client/:id" component={Client} />
                    <Route path="/clients" component={Clients} />
                    <Route path="/client-add" component={ClientsAdd} />
                    <Route path="/translation-add" component={TranslationAdd} />
                    <Route path="/translations" component={Translations} />
                    <Route path="/translation/:id" component={Translation} />
                </Switch>
            </Container>
            <Footer />
        </BrowserRouter>
    )
}

export default AppPage
