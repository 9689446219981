import React, { useState, useEffect } from 'react'
import InfoIcon from '@material-ui/icons/Info';

interface iMessageProps {
    desc: string;
    show?: boolean;
}

const Message: React.FC<iMessageProps> = ({desc, show}) => {

    const [visibility, setVisibility] = useState(show);

    useEffect(() => {
        setVisibility(false);
    }, [visibility])
    
    return (
        <div className={desc.length > 0 ? "c-message u-flx" : "u-hide"}>
            <InfoIcon />
            <span className="c-message__text">{desc}</span>
        </div>
    )
}

export default Message;
