import React, { useState, useContext, useEffect } from 'react'
import { Container, Grid, TextField, Box, Button, CircularProgress } from '@material-ui/core';
import { TranslationContext } from '../../context/TranslationContext';
import PageTitle from '../shared/PageTitle';
import Message from '../shared/Message';

import { Editor } from '@tinymce/tinymce-react';

const TranslationAdd: React.FC = () => {

    const { isLoading, message, setMessage, create } = useContext(TranslationContext);

    const [key, setKey] = useState("");
    const [content, setContent] = useState("<p>Neki text ovdje staviti</p>");

    useEffect(() => {
        // reset message
        setMessage("");
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            key,
            content
        };

        console.log(data);
        create(data);
    }

    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Dodavanje Ključa" 
                description="Ovdje možete dodati ključa." 
            />
            <Message desc={message} />
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="key" 
                            label="Ključ"
                            placeholder="eg. kontakt.tekst"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={event => setKey(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <Editor
                        apiKey="yr33g0dadacluat19wy927789wki9bh0fbzr0vksv66u2icn"
                        initialValue="<p>This is the initial content of the editor</p>"
                        init={{
                            height: 500,
                            menubar: false,
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help',
                            plugins: "lists",
                        }}
                        onChange={(data, event) => {
                            setContent(data.level.content);
                        }}
                    />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Spremi
                                <Box pl={2} className={isLoading ? "" :  "u-hide"}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default TranslationAdd
