import React, { useContext, useState, useEffect } from 'react'
import { Container, Grid, TextField, Box, Button, CircularProgress } from '@material-ui/core'
import { ClientContext } from '../../context/ClientContext';
import { useParams } from 'react-router-dom';
import PageTitle from '../shared/PageTitle';
import Message from '../shared/Message';

const Client: React.FC = () => {

    const { isLoading, client, updateClient, getClient, message, setMessage } = useContext(ClientContext);
    
    let { id } = useParams();

    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [order, setOrder] = useState("");

    // on first load, get client data
    useEffect(() => {
        setMessage("");
        getClient(id);
    }, [])
    // then when "client" variables changes state, set data
    useEffect(() => {
        setTitle(client.title);
        setSubTitle(client.subTitle);
        setOrder(client.order);
        console.log(client);
    }, [client])

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            id,
            title,
            subTitle,
            order
        };
        
        updateClient(data);
    }

    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Klijent" 
                description="Ovdje možete promijeniti naziv firme, dodatni opis ili poziciju na stranici" 
            />
            <Message desc={message} />
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="title" 
                            label="Ime firme"
                            placeholder="npr. BHTelecom"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={title == null ? "" : title}
                            onChange={event => setTitle(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="sub-title" 
                            label="Dodatni opis"
                            placeholder="npr. komunikacije"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={subTitle == null ? "" : subTitle}
                            onChange={event => setSubTitle(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="order" 
                            label="Pozicija na stranici"
                            placeholder="npr. neki broj od 0 do 2000"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={order == null ? "" : order}
                            onChange={event => setOrder(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Spremi
                                <Box pl={2} className={isLoading ? "" :  "u-hide"}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default Client
