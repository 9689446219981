import React, { useState, useContext } from 'react'
import { NavLink, Route, Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { LoginContext } from '../../context/LoginContext';

const Navigation = () => {

    const { removeToken, isLogedIn } = useContext(LoginContext);
    
    const [ anchorEl, setAnchorEl ] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleLogout = () => {
        const data = {
            token: localStorage.getItem('token')
        }
        removeToken(data);
    }

    return (
        <>
            <Route exact path="/">
                {!isLogedIn ? <Redirect to="/login" /> : ""}
            </Route>
            <Box mb={4}>
                <Box p={1}>
                    <Button
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={handleMenuOpen}
                        variant="contained" color="primary"
                    >
                        Navigacija
                    </Button>
                    <Button variant="outlined" color="primary" style={{float: "right"}} onClick={handleLogout}> Log out</Button>
                </Box>
                <Divider />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleMenuClose}>
                        <NavLink to="/clients">Klijenti</NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <NavLink to="/client-add">+ Klijenta</NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <NavLink to="/translations">Ključevi</NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <NavLink to="/translation-add">+ Ključ</NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <NavLink to="/users">+ Korisnika (ne radi)</NavLink>
                    </MenuItem>
                </Menu>
            </Box>
        </>
        
        
    )
}

export default Navigation
