import React, {useState, createContext } from "react";

export const SettingsContext = createContext(null);

const SettingsContextProvider = (props: any) => {
    const [ menuSettings, setMenuSettings ] = useState(
        { menuOpen: false } 
    )

    // here i could also put functions for hnadling this state => something like mobx
    const menuStateHandler = (open) => {
        setMenuSettings({menuOpen: open})
    }

    return (
        <SettingsContext.Provider value={{menuSettings, menuStateHandler}}>
            {props.children}
        </SettingsContext.Provider>
    )
}

export default SettingsContextProvider;