import React, { useContext, useEffect } from 'react'
import { ClientContext } from '../../context/ClientContext';
import { Link } from "react-router-dom";
import { Grid, ListItemText, ListItem, Divider, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PageTitle from '../shared/PageTitle';

const Clients: React.FC = () => {
    const { clients, getAllClients, deleteClient } = useContext(ClientContext);

    useEffect(() => {
        getAllClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteHandler = (id, e) => {
        e.preventDefault();
        console.log("deleted", id);
        deleteClient(id);
    }

    return (
        <Grid container>
            <PageTitle 
                name="Klijenti" 
                description="Lista klijenta. Klikom na stavku otvarate stranicu za uređivanje, a klikom na korpu brišete stavku." 
            />
            <Divider />
            <Grid item xs={12}>
                {clients && clients.map(item => {
                    const url = "/client/" + item.id;
                    return(
                        <Link to={url} key={item.id}>
                            <ListItem button>
                                <ListItemText className="" primary={item.title} />
                                <IconButton edge="end" aria-label="delete" onClick={(e) => deleteHandler(item.id, e)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                            <Divider />
                        </Link>
                    );
                })}
            </Grid>
        </Grid>
    )
}

export default Clients
