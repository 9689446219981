import React from 'react';
import SettingsContextProvider from './context/SettingContext';
import LoginContextProvider from './context/LoginContext';
import LoginForm from './components/login/LoginForm';
import AppPage from './components/app/AppPage';
import ClientContextProvider from './context/ClientContext';
import TranslationContextProvider from './context/TranslationContext';

import "./style/main.scss";

const App: React.FC = () => {
  if (localStorage.getItem('token')) {
    return (
      <div className="App">
        <SettingsContextProvider>
          <LoginContextProvider>
            <ClientContextProvider>
              <TranslationContextProvider>
                <AppPage />
              </TranslationContextProvider>
            </ClientContextProvider>
          </LoginContextProvider>
        </SettingsContextProvider>
      </div>
    )
  } else {
    return (
      <div className="App">
          <LoginContextProvider>
            <LoginForm />
          </LoginContextProvider>
      </div>
    )
  }
}

export default App;
