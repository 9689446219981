import React, { useState, useContext, useEffect } from 'react'
import { Container, Grid, TextField, Box, Button, CircularProgress } from '@material-ui/core';
import { ClientContext } from '../../context/ClientContext';
import PageTitle from '../shared/PageTitle';
import Message from '../shared/Message';

const ClientsAdd: React.FC = () => {

    const { isLoading, message, setMessage, create } = useContext(ClientContext);

    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    // const [order, setOrder] = useState("0");

    const handleTitleChange = event => setTitle(event.target.value);
    const handleSubTitleChange = event => setSubTitle(event.target.value);
    // const handleOrderChange = event => setOrder(event.target.value);

    useEffect(() => {
        // reset message
        setMessage("");
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            title,
            subTitle
        };

        console.log(data);
        create(data);
    }

    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Dodavanje Klijenta" 
                description="Ovdje možete dodati klijenta (ime i dodatni opis)." 
            />
            <Message desc={message} />
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="title" 
                            label="Title"
                            placeholder="eg. Name of the company"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleTitleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="sub-title" 
                            label="Subtitle"
                            placeholder="Placeholder"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleSubTitleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Spremi
                                <Box pl={2} className={isLoading ? "" :  "u-hide"}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default ClientsAdd
