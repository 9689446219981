import React, { useContext, useState, useEffect } from 'react'
import { Container, Grid, TextField, Box, Button, CircularProgress } from '@material-ui/core'
import { TranslationContext } from '../../context/TranslationContext';
import { useParams } from 'react-router-dom';
import PageTitle from '../shared/PageTitle';
import Message from '../shared/Message';

import { Editor } from '@tinymce/tinymce-react';

const Translation: React.FC = (props) => {

    const { isLoading, translation, updateTranslation, getTranslation, message, setMessage } = useContext(TranslationContext);
    
    const { id } = useParams();

    const [key, setKey] = useState("...loading");
    const [content, setContent] = useState("...loading");

    // on first load, get client data
    useEffect(() => {
        setMessage("");
        getTranslation(id);
    }, [])
    // then when "client" variables changes state, set data
    useEffect(() => {
        setKey(translation.key);
        setContent(translation.content);
    }, [translation])

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            id,
            key,
            content,
        };
        
        updateTranslation(data);
    }

    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Prevodi/ključevi" 
                description="Najbolje je samo promijeniti tekst u editoru, a ključeve nije preporučeno dirati." 
            />
            <Message desc={message} />
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                    <TextField
                            id="key" 
                            label="Ključ"
                            placeholder="eg. kontakt.tekst"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={event => setKey(event.target.value)}
                            value={key == null ? "" : key}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <Editor
                        apiKey="yr33g0dadacluat19wy927789wki9bh0fbzr0vksv66u2icn"
                        initialValue={content}
                        init={{
                            height: 500,
                            menubar: false,
                            toolbar:
                                'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright bullist numlist | \
                                | removeformat | help',
                            plugins: "lists",
                        }}
                        onChange={(data, event) => {
                            setContent(data.level.content);
                        }}
                    />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Spremi
                                <Box pl={2} className={isLoading ? "" :  "u-hide"}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default Translation
