import React, { useContext, useEffect } from 'react'
import { TranslationContext } from '../../context/TranslationContext';
import { Link } from "react-router-dom";
import { Grid, ListItemText, ListItem, Divider, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PageTitle from '../shared/PageTitle';

const Translations: React.FC = () => {
    const { translations, getAllTranslations, deleteTranslation } = useContext(TranslationContext);

    useEffect(() => {
        getAllTranslations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteHandler = (id, e) => {
        e.preventDefault();
        console.log("deleted", id);
        deleteTranslation(id);
    }

    return (
        <Grid container>
            <PageTitle 
                name="Prevodi/ključevi" 
                description="Lista ključeva koji se nalaze na stranici, na odredjenim mjestima. 
                Klikom na stavku otvarate stranicu za uređivanje, a klikom na korpu brišete stavku.
                Brisanje nije preporučeno raditi, jer necete znati koji se ključ nalazi na stranici.
                Najbolje ga je samo urediti/editovati i prilagoditi stranici." 
            />
            <Divider />
            <Grid item xs={12}>
                {translations && translations.map(item => {
                    const url = "/translation/" + item.id;
                    return(
                        <Link to={url} key={item.id}>
                            <ListItem button>
                                <ListItemText className="" primary={item.key} />
                                <IconButton edge="end" aria-label="delete" onClick={(e) => deleteHandler(item.id, e)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                            <Divider />
                        </Link>
                    );
                })}
            </Grid>
        </Grid>
    )
}

export default Translations
